@charset "utf-8";

.date_wrapper {
    height: 70px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-left: 10px;
    input {
        height: 35px;
        margin: 0 13px 0 20px;
        border-radius: 5px;
        border: 1px solid rgb(224, 225, 226);
        text-align: center;
        font-weight: 600;
        color: rgb(141, 141, 141);
        letter-spacing: -0.1px;
        font-size: 12px;
    }
    button {
        width: 70px;
        height: 35px;
        border-radius: 5px;
        border: none;
        background-color: rgb(2, 78, 238);
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        margin-left: 20px;
    }
}

.date_wrapper span {
    width: 25px;
    height: 25px;
    cursor: pointer;
}
section .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgb(250, 249, 249);
    align-items: center;
    margin-bottom: 40px;
}
.download_wrapper {
    border: 1px solid rgb(206, 206, 206);
    height: 35px;
    width: 160px;
    text-align: center;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: auto;
    }
    a {
        font-size: 14px;
        color: rgb(141, 141, 141);
        margin-left: 3px;
    }
}

.work_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 30px;
    align-items: center;
    ul {
        display: flex;
        gap: 0 20px;
        li a {
            color: rgb(141, 141, 141);
        }
        > .click {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 20px;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: #000;
            }
            a {
                color: #000;
            }
        }
    }
}
#workview {
    height: 35px;
    width: 160px;
    border: 1px solid rgb(206, 206, 206);
    appearance: none;
    background: url(../img/keyboard_arrow_down_24dp_FILL0_wght400_GRAD0_opsz24.svg)
        no-repeat right 10px center;
    padding-left: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
}
table {
    width: 100%;
    text-align: center;
}
thead {
    background-color: rgb(250, 249, 249);
    border-top: 1px solid rgb(206, 206, 206);
    border-bottom: 1px solid rgb(206, 206, 206);
}
th {
    color: rgb(141, 141, 141);
}
tr {
    height: 35px;
    font-size: 14px;
    line-height: 35px;
}
tbody tr {
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid rgb(206, 206, 206);
}
tbody tr td:nth-child(2) span {
    display: inline-block;
    width: auto;
    height: 25px;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 5px;
}
.work_data tr td:nth-child(2) span {
    background-color: rgba(131, 177, 245, 0.7);
    color: blue;
}
.work_end_data tr td:nth-child(2) span {
    background-color: rgba(238, 198, 89, 0.7);
    color: brown;
}
.not-work_data tr td:nth-child(2) span {
    background-color: rgba(226, 226, 226, 0.7);
    color: #000;
}
