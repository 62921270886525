.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 500px;
}

.wrapper h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.42;
  letter-spacing: -0.25px;
  text-align: center;
  color: rgb(51, 51, 51);
  margin-bottom: 24px;
}

.from_wrap label {
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 600;
}

.input_box {
  position: relative;
}

.input_icon {
  top: 50%;
  transform: translateY(-50%);
}

.user_id .input_icon {
  position: absolute;
  left: 11px;
}

.user_password .input_icon {
  position: absolute;
  left: 11px;
}

.input_box input {
  border: 1px solid rgb(222, 222, 222);
  padding: 15px 8px 15px 38px;
  font-size: 14px;
  border-radius: 5px;
  display: block;
  width: 100%;
  letter-spacing: -0.25px;
  font-weight: 400;
  color: rgb(51, 51, 51);
  box-sizing: border-box;
}

.input_box .input_error {
  border: 1px solid rgb(240, 55, 56);
}

.error_wrap {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}

.on {
  display: flex;
}

.error_wrap svg {
  display: block;
}

.error_txt {
  position: absolute;
  right: 0;
  top: 50px;
  background-color: #000;
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1;
  display: none;
}

.in {
  display: inline-block;
}

.error_wrap .error_txt::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #000;
  position: absolute;
  top: -9px;
  right: 10px;
}

.form_cont .user_password {
  margin-top: 25px;
}

.submit_cont {
  margin-top: 40px;
}

.submit_cont button,
.submit_cont a {
  display: block;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
}

.login_btn {
  background-color: rgb(2, 78, 238);
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
}

.main_signup_btn {
  margin-top: 15px;
  color: rgb(2, 78, 238);
}

.main_signup_btn:hover {
  background-color: rgb(241, 244, 255);
}

@media screen and (max-width: 750px) {
  .wrapper {
    width: 90%;
    max-width: 500px;
  }
  .wrapper h1 {
    font-size: clamp(20px, 4vw, 30px);
    margin-bottom: clamp(25px, 2.6vw, 50px);
  }
  .from_wrap label {
    font-size: clamp(14px, 2.4vw, 18px);
    margin-bottom: clamp(7px, 2vw, 15px);
  }
  .input_box input {
    font-size: clamp(13px, 2.27vw, 17px);
    padding: clamp(12px, 2.67vw, 20px) clamp(35px, 6vw, 45px);
  }
  .input_icon {
    left: 15px !important;
    width: clamp(17px, 2.67vw, 20px);
    height: clamp(17px, 2.67vw, 20px);
  }
  .user_password {
    margin-top: clamp(20px, 6.67vw, 50px) !important;
  }
  .error_wrap {
    width: clamp(45px, 6vw, 45px);
  }
  .error_wrap svg {
    width: clamp(19px, 3.33vw, 25px);
    height: clamp(19px, 3.33vw, 25px);
  }
  .error_txt {
    font-size: clamp(12px, 2.13vw, 16px);
    top: 100%;
    padding: clamp(8px, 1.6vw, 12px) clamp(10px, 2vw, 15px);
  }
  .error_txt::before {
    right: 13px !important;
  }
  .submit_cont {
    margin-top: clamp(30px, 8vw, 60px);
  }
  .submit_cont button,
  .submit_cont a {
    height: clamp(39px, 7.33vw, 55px);
    line-height: clamp(39px, 7.33vw, 55px);
    font-size: clamp(14px, 2.4vw, 18px);
  }
  .main_signup_btn {
    margin-top: clamp(15px, 4vw, 30px);
  }
}