main {
    border-top: 1px solid #ddd;
    padding: 0 !important;
}
main,
main > div,
main > div > div {
    display: flex;
    flex: 1;
    width: 100%;
}
.outer {
    width: 100%;
    .left_gnb {
        width: 200px;
        background: #fff;
        position: sticky;
        top: 78px;
        left: 0;
        padding: 20px;
    }
    section {
        width: calc(100% - 200px);
        background: #f9f9f9;
        padding: 20px;
    }
}

.left_gnb {
    h3 {
        font-weight: 700;
        font-size: 18px;
        padding-bottom: 30px;
    }
    .gnb_list {
        a {
            padding: 10px 12px;
            font-size: 14px;
            border-radius: 10px;
            font-weight: 700;
            &.on {
                background: #f1f4ff;
                color: #3562e4;
            }
        }
    }
}
@media (max-width: 750px) {
    main {
        border-top: none;
    }
    .outer {
        .left_gnb {
            display: none;
        }
        section {
            width: 100%;
        }
    }
}
