footer {
    .container {
        border-top: 1px solid #ddd;
        max-width: 100%;
        color: #757981;
        font-size: 13px;
        .info {
            line-height: 1.7;
        }
    }
    img {
        width: auto;
    }
}

@media screen and (max-width: 750px) {
    footer {
        .container {
            &.mo_only {
                position: fixed;
                z-index: 100;
                bottom: 0;
                left: 0;
                width: 100%;
                background: #fff;
                border-top: 1px solid #ddd;
            }
        }
        .links {
            grid-template-columns: repeat(5, 1fr);
            a {
                font-weight: 600;
                font-size: clamp(11px, 2.67vw, 20px);
                svg {
                    width: clamp(18px, 5.33vw, 40px);
                    height: clamp(18px, 5.33vw, 40px);
                    margin-bottom: clamp(8px, 2vw, 15px);
                }
            }
        }
    }
}
