.box01 {
    background: #fff;
    border-radius: 10px;
    padding: 25px 30px;
    margin-bottom: 20px;
}
.half {
    width: calc(50% - 10px);
}

.btn01 {
    font-size: 14px;
    color: #fff;
    padding: 13px 40px;
    background: #3562e4;
    border-radius: 10px;
    font-weight: 600;
}

.tit_ara {
    color: #333;
    h2 {
        font-size: clamp(22px, 1.25vw, 24px);
        line-height: 1.33;
        font-weight: 600;
    }
    p {
        font-size: clamp(14px, 0.83vw, 16px);
    }
}

.tit01 {
    color: #333;
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;
}

.tit02 {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 5px;
}

.arrow_cont_box01 {
    button {
        width: 25px;
        height: 25px;
    }
    .left_arrow {
        margin-right: 30px;
    }
    .right_arrow {
        margin-left: 30px;
    }
}

.time {
    p {
        line-height: 1;
        color: #333;
        font-weight: 600;
        font-size: 18px;
        span {
            line-height: 1.5;
            color: #888;
            font-size: 15px;
        }
    }
}
.work_time {
    color: #888;
    font-weight: 800;
    span {
        color: #3562e4;
    }
}
.percentage {
    height: 25px;
    border-radius: 25px;
    line-height: 25px;
    background: #ffeaea;
    position: relative;
    .current {
        background: #3562e4;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 13%;
        border-radius: 25px;
        z-index: 2;
        height: 100%;
        font-size: 14px;
        font-weight: 500;
    }
    .standard {
        position: absolute;
        top: 0;
        left: 0;
        width: 87%;
        height: 100%;
        z-index: 1;
        border-radius: 25px;
        background: #eee;
    }
}

.info {
    dl {
        line-height: 1.6;
        font-size: 14px;
        dt {
            color: #888;
        }
        dd {
            font-weight: 600;
            color: #333;
        }
        .memo {
            svg {
                cursor: pointer;
                margin-right: 3px;
            }
            input {
                width: 50%;
                border-bottom: 1px solid #aaa;
            }
        }
    }
}
