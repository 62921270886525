header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  z-index: 100;
  background: #fff;
}
header .wrapper {
  padding: 0 20px;
}
header .logo {
  width: 100px;
}
header .util {
  margin-left: auto;
  margin-right: 0;
}
header .util a,
header .util button {
  width: 80px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  display: inline-block;
  transition: 0.3s;
}
header .util .login_btn {
  color: #7e7e7e;
  margin-right: 10px;
}
header .util .login_btn:hover {
  background-color: rgb(241, 244, 255);
}
header .util .sign_btn {
  background-color: #3562e4;
  color: #fff;
}
header .util .user,
header .util .alarm,
header .util .logout_btn {
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin-right: 10px;
  border-radius: 100%;
  filter: grayscale(0.5);
}
header .util .user svg,
header .util .alarm svg,
header .util .logout_btn svg {
  transition: 0.4s;
}
header .util .user:hover,
header .util .alarm:hover,
header .util .logout_btn:hover {
  filter: sepia(0);
}
header .util .user {
  font-size: 18px;
  background: #226ce0;
  color: #fff;
  margin-right: 0;
}
header .util .alarm,
header .util .logout_btn {
  background: #e3e5ec;
}
header .gnb {
  margin-left: 70px;
}
header .gnb a {
  font-weight: 600;
  margin-right: 35px;
}
header .gnb .logout_btn {
  display: none;
}

@media screen and (max-width: 750px) {
  header {
    padding: clamp(15px, 4vw, 30px) 0;
  }
  header .wrapper {
    padding: 0 3vw;
  }
  header .logo {
    width: clamp(100px, 17.33vw, 130px);
  }
  header .util a,
  header .util button {
    font-size: clamp(14px, 2.4vw, 18px);
    width: clamp(65px, 13.33vw, 100px);
    height: clamp(35px, 6vw, 45px);
    line-height: clamp(35px, 6vw, 45px);
  }
  header .util .user,
  header .util .alarm,
  header .util .logout_btn {
    width: clamp(30px, 6.67vw, 50px);
    height: clamp(30px, 6.67vw, 50px);
    line-height: clamp(30px, 6.67vw, 50px);
  }
  header .util .user {
    font-size: clamp(18px, 3.33vw, 25px);
  }
  header .gnb {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
    background-color: #fff;
    z-index: 100;
    padding: 100px 4%;
    border-left: 1px solid #226ce0;
  }
  header .gnb .links {
    flex-wrap: wrap;
  }
  header .gnb .links a {
    width: 100%;
    font-size: 30px;
    padding: 20px 0;
  }
  header .gnb .logout_btn {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 4%;
    font-size: 18px;
    font-weight: 700;
    color: #bbb;
    transition: 0.4s;
  }
  header .gnb .logout_btn:hover {
    color: #226ce0;
  }
}