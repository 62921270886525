@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
/* 여백 초기화 */
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li,
dl,
dt,
dd,
table,
tr,
td,
th,
form,
fieldset,
legend,
input,
select,
textarea,
button,
label,
address,
img,
a,
header,
main,
section,
nav,
article,
footer,
div,
span {
  margin: 0;
  padding: 0;
}

/* 리스트 타입 초기화 */
ol,
ul,
li,
dl,
dd,
dt {
  list-style: none;
}

/* 이미지, 필드셋 테두리 제거 */
/* iE 10에서 링크가 걸렸을 때 테두리 제거*/
img,
fieldset {
  border: 0 none;
}

/* 스크롤 부드럽게 */
html {
  scroll-behavior: smooth;
}

/* 타이포 설정 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
th,
td {
  font-family: "Pretendard Variable";
  font-size: 16px;
  word-break: keep-all;
  color: #231815;
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.03em;
  -webkit-text-size-adjust: 100%; /* ios 방향 변경시 글꼴 크기 조정 방지 */
}

/* 폰트 스타일 강제 상속 */
button,
input,
optgroup,
select,
option,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: normal;
}

/* a요소 장식 제거, 글자색상 변경 */
a,
a:active,
a:hover {
  text-decoration: none;
  color: inherit;
  background: transparent; /* IE10에서 배경색 그레이 방지 */
}

/* float clear설정 */
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

/* 컨텐츠 화면에서 숨기기 */
.blind {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip-path: polygon(0 0, 0 0, 0 0);
}

/* 박스 계산법 변경 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* 인라인요소 수직정렬 */
img {
  vertical-align: top;
}

input,
button,
select,
option,
optgroup,
textarea {
  vertical-align: middle;
}

/* iE에서 block 처리 */
main {
  display: block;
}

/* Firefox, Edge, iE 에서 스타일 매칭*/
hr {
  height: 0;
  overflow: visible;
}

/* Chrome ,Edge, Safari에서 스타일 매칭*/
b,
strong {
  font-weight: bolder;
}

/* 윗첨자, 아래첨자 */
/* 상위, 하위 요소의 line hight에 영향을 주지 않기 위해*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -1em; /* 디자인에 따라 값 변경하기*/
}

sup {
  top: -1em; /* 디자인에 따라 값 변경하기*/
}

/*--------------------------------------------------*/
img {
  width: 100%;
  height: auto;
}

table {
  border-collapse: collapse; /* 라인 하나 */
  border-spacing: 0; /*라인 간격 0*/
}

button {
  border: 0 none;
  background: transparent;
  cursor: pointer;
}

input:focus {
  outline: none;
}